<template>
    <v-sheet>
        <v-tabs
            v-model="tabComponente"
            dark
            fixed-tabs
            background-color="tnlogAzulEscuro"
        >
            <v-tab v-for="(item, index) in form.tabsConteudo" :key="index">
                    {{ index }}
            </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tabComponente">
            <v-form :ref="formNome">
                <!--
                    DADOS
                 -->
                <v-tab-item eager class="py-4">
                    <v-row>
                        <v-col cols="4" sm="2">
                            <InputTextV2
                                v-model="form.tabsConteudo.dados.id_lista_ramal"
                                :form="form"
                                campo="id_lista_ramal"
                            />
                        </v-col>
                        <v-col cols="12" sm="10">
                            <InputTextV2
                                v-model="form.tabsConteudo.dados.nome"
                                :form="form"
                                campo="nome"
                            />
                        </v-col>
                        <v-col class="pt-0" cols="6" sm="6">
                            <InputTextV2
                                v-model="form.tabsConteudo.dados.ramal"
                                :form="form"
                                campo="ramal"
                            />
                        </v-col>
                        <v-col class="pt-0" cols="6" sm="6">
                            <InputTextV2
                                v-model="form.tabsConteudo.dados.celular"
                                :form="form"
                                campo="celular"
                            />
                        </v-col>
                        <v-col class="pt-0" cols="12" sm="12">
                            <InputTextV2
                                v-model="form.tabsConteudo.dados.email"
                                :form="form"
                                campo="email"
                            />
                        </v-col>
                    </v-row>
                </v-tab-item>

                <!--<v-tab-item eager>
                    <v-row>
                        <v-col cols="4" sm="2">
                            <InputTextV2
                                v-model="form.tabsConteudo.teste.id_teste"
                                :form="form"
                                campo="id_teste"
                            />
                        </v-col>
                    </v-row>
                </v-tab-item> -->
            </v-form>
        </v-tabs-items>

        <BuscaModal
            ref="BuscaModal"
            @inputAutoCompletaAtualizaValor="inputAutoCompletaAtualizaValor"
            @mensagemAlerta="$emit('mensagemAlerta', $event)"
        />
    </v-sheet>
</template>

<script>
import _ from 'lodash';

import { API_URL } from '@/config/configs';
import { Alerta, MetodoNovoOuAtualizaApi } from '@/helpers/Funcoes.js';
import ApiService from '@/services/ApiService.js';
import DatatableHelper from '@/helpers/Datatables.js';
import {
    Maximo,
    Minimo,
    Obrigatorio,
    Email
} from '@/helpers/ValidadorCampo.js';

import BuscaModal from '@/components/Default/BuscaModal.vue';
import InputTextV2 from '@/components/Form/InputTextV2.vue';

export default {
    components: {
        BuscaModal,
        InputTextV2
    },
    props: ['refBuscaModal'],
    async created() {
        this.formOriginal = _.cloneDeep(this.form);
    },
    watch: {},
    computed: {},
    mounted() {},
    methods: {
        preencheFormulario(conteudo) {
            this.form.tabsConteudo.dados = _.pick(
                conteudo,
                Object.keys(this.form.tabsConteudo.dados)
            );
        },
        FormataDadosParaEnviarForm() {
            let form = [];

            form = {
                dados: Object.assign(this.form.tabsConteudo.dados)
            };

            return form;
        },
        async novoForm() {
            try {
                if (this.urlNovoRegistro == '') {
                    this.$store.commit('FORM_ACAO', 'form-novo');
                    this.$store.dispatch('painelAbre');

                    return false;
                }

                const regexp = /\${([^{]+)}/g;

                let urlConsulta = `${
                    API_URL.erp[this.moduloNome][this.urlNovoRegistro]
                }`.replace(regexp, function(ignore, key) {
                    return eval(key);
                });

                let urlConfig = {
                    method: 'get',
                    url: `${urlConsulta}`,
                    data: {}
                };

                await ApiService(urlConfig)
                    .then(resposta => {
                        this.$store.commit('FORM_ACAO', 'form-novo');

                        this.form.complemento = resposta.data.conteudo
                            .complemento
                            ? resposta.data.conteudo.complemento
                            : {};

                        this.$store.dispatch('painelAbreFecha');
                    })
                    .catch(erro => {
                        throw new Error(erro.message);
                    });
            } catch (e) {
                this.$emit('mensagemAlerta', e.message, 'error');
            }
        },
        async consultaRegistro(id_registro) {
            try {
                this.tabComponente = 'Dados';

                const regexp = /\${([^{]+)}/g;

                let urlConsulta = `${
                    API_URL.erp[this.moduloNome][this.urlConsultaRegistro]
                }`.replace(regexp, function(ignore, key) {
                    return eval(key);
                });

                let urlConfig = {
                    method: 'get',
                    url: `${urlConsulta}`,
                    data: {}
                };

                await ApiService(urlConfig)
                    .then(resposta => {
                        this.$store.commit('FORM_ACAO', 'form-atualiza');

                        this.preencheFormulario(resposta.data.conteudo.dados);

                        if (
                            resposta.data.conteudo.hasOwnProperty('complemento')
                        ) {
                            this.form.complemento = {
                                ...this.form.complemento,
                                ...resposta.data.conteudo.complemento
                            };
                        }

                        this.$store.dispatch('painelAbreFecha');
                    })
                    .catch(erro => {
                        throw new Error(erro.message);
                    });
            } catch (e) {
                Alerta(e.message, 'error');
            }
        },
        fechaForm() {
            try {
                this.$store.commit('FORM_ACAO', 'form-grid');
                this.limpaFormulario();
                this.$emit('datatableRecarrega');
                this.$store.dispatch('painelFecha');
                window.scrollTo(0, 0);
            } catch (e) {
                this.$emit('mensagemAlerta', e.message, 'error');
            }
        },
        async salvaForm() {
            try {
                if (!this.$refs[this.formNome].validate()) {
                    this.$emit(
                        'mensagemAlerta',
                        'Preencha corretamente todos campos',
                        'error'
                    );

                    return false;
                }

                await this.$root.$refs.$confirmaAcaoModal
                    .open('Salvar', 'Salvar registro ?', {
                        cor: 'green'
                    })
                    .then(async confirma => {
                        if (!confirma) {
                            return false;
                        }

                        let conteudoFormulario = this.FormataDadosParaEnviarForm(
                            this.form.tabsConteudo
                        );

                        //console.log('envia form', conteudoFormulario);
                        //return;

                        let urlForm = conteudoFormulario.dados[
                            this.chavePrimaria
                        ]
                            ? `${API_URL.erp[this.moduloNome].salva}/${
                                  conteudoFormulario.dados[this.chavePrimaria]
                              }`
                            : `${API_URL.erp[this.moduloNome].salva}`;

                        let urlConfig = {
                            method: MetodoNovoOuAtualizaApi(
                                conteudoFormulario.dados[this.chavePrimaria]
                            ),
                            url: urlForm,
                            data: conteudoFormulario
                        };

                        await ApiService(urlConfig)
                            .then(resposta => {
                                this.$store.commit(
                                    'FORM_ACAO',
                                    'form-atualiza'
                                );

                                this.$emit(
                                    'mensagemAlerta',
                                    resposta.data.mensagem,
                                    'success'
                                );

                                this.preencheFormulario(
                                    resposta.data.conteudo.dados
                                );

                                if (
                                    resposta.data.conteudo.hasOwnProperty(
                                        'complemento'
                                    )
                                ) {
                                    this.form.complemento = {
                                        ...this.form.complemento,
                                        ...resposta.data.conteudo.complemento
                                    };
                                }
                            })
                            .catch(erro => {
                                throw new Error(erro.message);
                            });
                    });
            } catch (e) {
                this.$emit('mensagemAlerta', e.message, 'error');
            }
        },
        async apagaForm() {
            try {
                let dados = DatatableHelper.datatableGetLinhaSelecionadas(
                    this.moduloNome
                );

                let ids = dados
                    .map(function(dado) {
                        return dado.DT_RowId;
                    })
                    .join();

                if (!ids) {
                    this.$emit(
                        'mensagemAlerta',
                        'Selecione um registro antes de apagar.',
                        'error'
                    );

                    return false;
                }

                await this.$root.$refs.$confirmaAcaoModal
                    .open('Deleta', `Apagar registro(s) ?\n IDS - ${ids}`, {
                        cor: 'red'
                    })
                    .then(async confirma => {
                        if (!confirma) {
                            return false;
                        }

                        let urlConfig = {
                            method: 'DELETE',
                            url: `${API_URL.erp[this.moduloNome].apaga}/${ids}`,
                            params: { [this.chavePrimaria]: ids }
                        };

                        let dados = await ApiService(urlConfig)
                            .then(resposta => {
                                this.$emit('datatableRecarrega');

                                this.$emit(
                                    'mensagemAlerta',
                                    `Registro ${ids} Deletado com sucesso`,
                                    'success'
                                );
                            })
                            .catch(erro => {
                                throw new Error(erro.message);
                            });
                    });
            } catch (e) {
                this.$emit('mensagemAlerta', e.message, 'error');
            }
        },
        limpaFormulario() {
            //this.$refs[this.formNome].reset();

            this.form = _.cloneDeep(this.formOriginal);
        },
        atualizaFormComplemento(complemento) {
            Object.keys(this.form.complemento).map((chave, idx) => {
                if (complemento[chave]) {
                    this.form.complemento[chave] = complemento[chave];
                }
            });

            this.formOriginal.complemento = this.form.complemento;
        },
        abrePesquisa(
            objetoClicado,
            objetoRetorno,
            formatoRetornado,
            titulo,
            datatableBusca
        ) {
            this.$refs['BuscaModal'].abreModal = true;
            this.$refs['BuscaModal'].titulo = titulo;
            this.$refs['BuscaModal'].objetoClicado = objetoClicado;
            this.$refs['BuscaModal'].objetoRetorno = objetoRetorno;
            this.$refs['BuscaModal'].formatoRetornado = formatoRetornado;
            this.$refs['BuscaModal'].datatableBusca = datatableBusca;
        },
        inputAutoCompletaAtualizaValor(objectoOpcoes, valorDefinido) {
            this.$refs['BuscaModal'].objetoClicado.atualizaValor(
                objectoOpcoes,
                valorDefinido
            );
        }
    },
    data() {
        return {
            apiUrl: API_URL,
            tabComponente: 'Dados',
            formNome: 'formListaRamal',
            moduloNome: 'listaramal',
            chavePrimaria: 'id_lista_ramal',
            urlNovoRegistro: '',
            urlConsultaRegistro: 'listaPorIdForm',
            formOriginal: {},
            form: {
                tabsConteudo: {
                    dados: {
                        id_lista_ramal: '',
                        nome: '',
                        ramal: '',
                        celular: '',
                        email: ''
                    },
                },
                complementoOriginal: {},
                complemento: {},
                configuracao: {
                    campo: {},
                    campoConfiguracao: {
                        id_lista_ramal: {},
                        nome: {},
                        ramal: {},
                        celular: { campoFormatacao: '(##) # ####-####' },
                        email: {},
                    },
                    tamanho: {
                        id_lista_ramal: {},
                        nome: { min: 2, max: 100 },
                        ramal: { min: 1, max: 3 },
                        celular: { min: 16, max: 16 },
                        email: { min: 13, max: 40 },
                    },
                    regra: {
                        id_lista_ramal: [],
                        nome: [Obrigatorio(), Minimo(2), Maximo(100)],
                        ramal: [Obrigatorio(), Minimo(1), Maximo(3)],
                        celular: [Maximo(16)],
                        email: [Minimo(13), Maximo(40), Email()],
                    },
                    placeholder: {
                        id_lista_ramal: 'ID',
                        nome: 'Nome',
                        ramal: 'Ramal',
                        celular: 'Celular',
                        email: 'E-mail',
                    },
                    colunas: {},
                    obrigatorio: {
                        id_lista_ramal: false,
                        nome: true,
                        ramal: true,
                        celular: false,
                        email: true,
                    },
                    desativado: {
                        id_lista_ramal: true,
                        nome: false,
                        ramal: false,
                        celular: false,
                        email: false,
                    },
                    dataTableVuetify: {}
                }
            }
        };
    }
};
</script>

<style scoped></style>
